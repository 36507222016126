<div class="p-grid"  >
    <div class="p-col-12 p-md-12">
        <p-panel header="Info Pasien" [toggleable]="true">
            <p-scrollPanel [style]="{width: '100%', height: '55vh'}">
                <div class="p-grid" *ngIf="item.pasien.nocm!=undefined">
                    <div class="p-col-12 p-md-12  timeline">
                        <ul>
                            <li class="satu"><i class="pi pi-circle-on"></i>
                                <div class="event-content">
                                    <span>No Registrasi</span>
                                    <span class="event-title">{{item.pasien.noregistrasi}}</span>
                                </div>
                            </li>
                            <li class="dua"><i class="pi pi-circle-on"></i>
                                <div class="event-content">
                                    <span>No Rekam Medis</span>
                                    <span class="event-title">{{item.pasien.nocm}}</span>
                                </div>
                            </li>
                            <li class="tiga"><i class="pi pi-circle-on"></i>
                                <div class="event-content">
                                    <span>Nama Pasien</span>
                                    <span class="event-title">{{item.pasien.namapasien}}</span>
                                </div>
                            </li>
                            <li class="empat"><i class="pi pi-circle-on"></i>
                                <div class="event-content">
                                    <span> Tgl Registrasi </span>
                                    <span class="event-title">{{item.pasien.tglregistrasi}}</span>
                                </div>
                            </li>
                            <li class="satu"><i class="pi pi-circle-on"></i>
                                <div class="event-content">
                                    <span> Jenis Pasien </span>
                                    <span class="event-title">{{item.pasien.kelompokpasien}}</span>
                                </div>
                            </li>
                            <li class="lima"><i class="pi pi-circle-on"></i>
                                <div class="event-content">
                                    <span>Jenis Kelamin</span>
                                    <span class="event-title">{{item.pasien.jeniskelamin}}</span>
                                </div>
                            </li>
                            <li class="enam"><i class="pi pi-circle-on"></i>
                                <div class="event-content">
                                    <span>Tgl Lahir</span>
                                    <span class="event-title">{{item.pasien.tgllahir}}</span>
                                </div>
                            </li>
                            <li class="tujuh"><i class="pi pi-circle-on"></i>
                                <div class="event-content">
                                    <span>Umur</span>
                                    <span class="event-title">{{item.pasien.umur}}</span>
                                </div>
                            </li>
                            <li class="satu"><i class="pi pi-circle-on"></i>
                                <div class="event-content">
                                    <span>Alamat</span>
                                    <span class="event-title">{{item.pasien.alamatlengkap}}</span>
                                </div>
                            </li>
                            <li class="dua"><i class="pi pi-circle-on"></i>
                                <div class="event-content">
                                    <span>Kelas </span>
                                    <span class="event-title">{{item.pasien.namakelas}}</span>
                                </div>
                            </li>
                            <li class="tiga"><i class="pi pi-circle-on"></i>
                                <div class="event-content">
                                    <span>Ruangan </span>
                                    <span class="event-title">{{item.pasien.namaruangan}}</span>
                                </div>
                            </li>
                            <li class="empat"><i class="pi pi-circle-on"></i>
                                <div class="event-content">
                                    <span>Jenis Pelayanan </span>
                                    <span class="event-title">{{item.pasien.jenispelayanan}}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!-- <div class="p-col-12 p-md-1">
                        <div class="p-grid">
                            <div class="p-col-12">
                                <p-card header="" [style]="{width: '90px','height':'90px'}" styleClass="p-card-shadow">
                                    <ng-template pTemplate="header">
                                        <i class="pi pi-user" style="font-size: 50px;padding:20px;"></i>
                                    </ng-template>
                                </p-card>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="p-col-12 p-md-12">
                        <div class="p-grid">
                            <div class="p-col-12 p-md-3">
                                <label class="label" for="input">No Registrasi </label>
                                <input type="text" [(ngModel)]="item.pasien.noregistrasi" pInputText
                                    placeholder="Cari No Registrasi" disabled />
                            </div>
                            <div class="p-col-12 p-md-3">
                                <label class="label" for="input">No Rekam Medis </label>
                                <input type="text" [(ngModel)]="item.pasien.nocm" pInputText placeholder="No Rekam Medis"
                                    disabled />
                            </div>
                            <div class="p-col-12 p-md-3">
                                <label class="label" for="input">Nama Pasien </label>
                                <input type="text" [(ngModel)]="item.pasien.namapasien" pInputText placeholder="Nama Pasien"
                                    disabled />
                            </div>
                            <div class="p-col-12 p-md-3">
                                <label class="label" for="input">Tgl Registrasi </label>
                                <input type="text" [(ngModel)]="item.pasien.tglregistrasi" pInputText placeholder=""
                                    disabled />
                            </div>
                            <div class="p-col-12 p-md-3">
                                <label class="label" for="input">Jenis Kelamin </label>
                                <input type="text" [(ngModel)]="item.pasien.jeniskelamin" pInputText
                                    placeholder="Nama Pasien" disabled />
                            </div>
                            <div class="p-col-12 p-md-3">
                                <label class="label" for="input">Tgl Lahir </label>
                                <input type="text" [(ngModel)]="item.pasien.tgllahir" pInputText placeholder="Tgl Lahir"
                                    disabled />
                            </div>
                            <div class="p-col-12 p-md-3">
                                <label class="label" for="input">Umur </label>
                                <input type="text" [(ngModel)]="item.pasien.umur" pInputText placeholder="" disabled />
                            </div>
                            <div class="p-col-12 p-md-3">
                                <label class="label" for="input">Alamat </label>
                                <input type="text" [(ngModel)]="item.pasien.alamatlengkap" pInputText placeholder="Alamat"
                                    disabled />
                            </div>
                            <div class="p-col-12 p-md-3">
                                <label class="label" for="input">Kelas </label>
                                <input type="text" [(ngModel)]="item.pasien.namakelas" pInputText placeholder="" disabled />
                            </div>
                            <div class="p-col-12 p-md-3">
                                <label class="label" for="input">Ruangan </label>
                                <input type="text" [(ngModel)]="item.pasien.namaruangan" pInputText placeholder=""
                                    disabled />
                            </div>
                            <div class="p-col-12 p-md-3">
                                <label class="label" for="input">Pendidikan </label>
                                <input type="text" [(ngModel)]="item.pasien.pendidikan" pInputText placeholder="Pendidikan"
                                    disabled />
                            </div>
    
                            <div class="p-col-12 p-md-3">
                                <label class="label" for="input">Jenis Pelayanan </label>
                                <input type="text" [(ngModel)]="item.pasien.jenispelayanan" pInputText placeholder=""
                                    disabled />
                            </div>
                        </div>
                    </div> -->
    
                </div>
                <div class="p-grid" *ngIf="item.pasien.nocm==undefined">
                    <div class="p-col-12 p-md-12" *ngFor="let itemzz of numberss">
                        <p-skeleton height="4rem"></p-skeleton>
                    </div>
                </div>
            </p-scrollPanel>
            
        </p-panel>
    </div>
</div>
