import {Component} from '@angular/core';
import * as moment from 'moment';
import {AppMainComponent} from './app.main.component';

@Component({
    selector: 'app-rightmenu',
    templateUrl: './app.rightmenu.component.html'
})
export class AppRightMenuComponent {
    date: Date;
    today:any= moment(new Date()).format('DD dddd, MMM YYYY')
    constructor(public appMain: AppMainComponent) {}
}
