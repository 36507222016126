<app-loader></app-loader>
<div class="layout-breadcrumb-container p-d-flex p-ai-center p-jc-between">

    <div class="layout-breadcrumb-left-items p-d-flex p-ai-center">
        <a href="#" class="menu-button" (click)="appMain.onMenuButtonClick($event)" *ngIf="appMain.isStatic() && !appMain.isMobile()">
            <i class="pi pi-bars"></i>
        </a>

        <p-breadcrumb [model]="items" styleClass="layout-breadcrumb p-py-2"></p-breadcrumb>
    </div>
    <div class="layout-breadcrumb-right-items p-d-flex">
        <a tabindex="0" class="search-icon" (click)="appMain.searchActive = true; appMain.searchClick = true;">
            <i class="pi pi-search"></i>
        </a>

        <div class="search-wrapper" [ngClass]="{'active-search-wrapper': appMain.searchActive}">
            <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <!-- <p-autoComplete [(ngModel)]="appMain.itemMenu" [suggestions]="appMain.filteredMenu" 
                (completeMethod)="appMain.filterMenu($event)" field="label" (onSelect)="appMain.onSelectSearchMenu($event)" 
                placeholder="Search Menu..." [minLength]="2"  
                ></p-autoComplete> -->
                <input type="text" pInputText placeholder="Search..." [(ngModel)]="search" (click)="appMain.searchClick=true"/>
            </span>
        </div>

        <button pButton pRipple type="button" label="Today" icon="pi pi-bookmark" *ngIf="!appMain.isMobile()"
                class="layout-rightmenu-button" (click)="appMain.onRightMenuButtonClick()"></button>
        <button pButton pRipple type="button" icon="pi pi-bookmark" *ngIf="appMain.isMobile()"
                class="layout-rightmenu-button" (click)="appMain.onRightMenuButtonClick()"></button>
    </div>
</div>
