export class Config {
	static get() {
		if (window.location.hostname.indexOf('localhost') > -1 || window.location.hostname.indexOf('127.') > -1) {
			return lokal;
		} else if (window.location.hostname.indexOf('203.210') > -1) {
			return publics;
		} else if (window.location.hostname.indexOf('netmedic') > -1) {
			return hostinghttps;
		}else if (window.location.hostname.indexOf('xxx') > -1) {
			return lokaluing;
		} else {
			return lokalDEUI;
		}
	}
	static profile() {
		return {
			namaProfile: "RSUD Pambalah Batung Amuntai",
			alamat: "",
		}
	}
}

var lokal = {
	apiBackend: "http://localhost:8201/",
	apiNotif: "http://127.0.0.1:8201/",
	socketIO: "http://127.0.0.1:77770",
	page: 0,
	rows: 10
};

var lokalDEUI = {
	apiBackend: "/",
	apiNotif: "/",
	socketIO: "",
	page: 0,
	rows: 10
};
var lokaluing = {
	apiBackend: "http://192.168.0.145/",
	apiNotif: "http://127.0.0.1:8000/",
	socketIO: "http://192.168.0.145:7777",
	page: 0,
	rows: 10
};

var publics = {
	apiBackend: "http://203.210.87.87:7772/",
	apiNotif: "http://127.0.0.1:8000/",
	socketIO: "http://127.0.0.1:7777",
	page: 0,
	rows: 10
};

var hostinghttps = {
	apiBackend: "https://api.netmedic.id/",
	apiNotif: "https://api.netmedic.id/",
	socketIO: "https://socket.netmedic.id",
	page: 0,
	rows: 10
};
