import {Component, OnInit} from '@angular/core';
import { AuthService } from 'src/app/service';
import {AppBreadcrumbService} from '../../app.breadcrumb.service';

@Component({
    templateUrl: './emptydemo.component.html'
})
export class EmptyDemoComponent implements OnInit {
    namaProfile=''
    constructor(private breadcrumbService: AppBreadcrumbService,
       ) {
        this.breadcrumbService.setItems([
            { label: 'Pages' },
            { label: 'Home', routerLink: [''] }
        ]);
    }
    ngOnInit() {
        if (localStorage.getItem('RUdJRVJBTURBTg==')) {
            let user = JSON.parse(localStorage.getItem('RUdJRVJBTURBTg=='))
            this.namaProfile = user.profile.namalengkap

        } else if (sessionStorage.getItem('RUdJRVJBTURBTg==')) {
            let user = JSON.parse(sessionStorage.getItem('RUdJRVJBTURBTg=='))
            this.namaProfile = user.profile.namalengkap

        }
        // this.namaProfile = this.auth.getDataLoginUser().profile.namaProfile
      }
}
