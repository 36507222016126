<div class="layout-wrapper" (click)="onLayoutClick()" [ngClass]="{'layout-static': app.menuMode === 'static',
                'layout-overlay': app.menuMode === 'overlay',
                'layout-slim': app.menuMode === 'slim',
                'layout-horizontal': app.menuMode === 'horizontal',
                'layout-sidebar': app.menuMode === 'sidebar',
                'layout-sidebar-static': app.menuMode === 'sidebar' && sidebarStatic,
                'layout-overlay-active': overlayMenuActive,
                'layout-mobile-active': staticMenuMobileActive,
                'layout-static-inactive': staticMenuDesktopInactive && app.menuMode === 'static',
                 'p-ripple-disabled': !app.ripple}">
    <p-dialog [(visible)]="display" [modal]="true">
        <p-header>
            Sesi Berakhir
        </p-header>
        Anda Akan Logout
        <p-footer>
            <button type="button" pButton (click)="keluar()" label="Logout"></button>
        </p-footer>
    </p-dialog>
    <p-toast position="center" key="t-logout" (onClose)="onReject()" [baseZIndex]="5000">
        <ng-template let-message pTemplate="message">
            <div class="p-flex p-flex-column" style="flex: 1">
                <div class="p-text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h4>{{message.summary}}</h4>
                </div>
                <div class="p-grid p-fluid">
                    <div class="p-col-6">
                        <button type="button" pButton (click)="onConfirm()" label="Ya"
                            class="p-button-primary"></button>
                    </div>
                    <div class="p-col-6">
                        <button type="button" pButton (click)="onReject()" label="Tidak"
                            class="p-button-secondary"></button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-toast>

    <div class="layout-main" *ngIf="isLogin">
        <p-toast position="bottom-right" key="socket">
            <ng-template let-message pTemplate="message">
                <div class="p-flex p-flex-column" style="flex: 1">
                    <div class="p-text-center">
                        <i class="pi pi-exclamation-triangle p-mr-2"></i>
                        <span style="font-size: 15px;font-weight: bold;">{{message.summary}}</span>
                        <p>{{setJudulNotif(message.detail)}}</p>
                    </div>
                    <div class="p-grid p-fluid">
                        <div class="p-col-4 p-md-offset-4">
                            <button type="button" pButton  label="Lihat" (click)="onSelectNotifToast(message.detail)"  class="p-button-outlined p-button-success" icon="pi pi-search"  styleClass="p-button-sm"></button>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-toast>
        <p-toast key="t-notif"></p-toast>
        <p-toast position="bottom-right" key="br"></p-toast>
        <app-topbar *ngIf="isMobile() || isOverlay() || isHorizontal()"></app-topbar>

        <app-menu *ngIf="!isMobile() && (isStatic() || isSlim() || isSidebar())"></app-menu>

        <app-breadcrumb></app-breadcrumb>

        <div class="layout-main-content">
            <router-outlet></router-outlet>
        </div>

        <app-footer *ngIf="isLogin"></app-footer>
    </div>

    <app-rightmenu *ngIf="isLogin"></app-rightmenu>

    <app-config *ngIf="isLogin"></app-config>

</div>
