<div class="login-body">
    <p-toast [style]="{marginTop: '2%'}" position="top-center" key="t-notif"></p-toast>
    <p-progressBar *ngIf="loading" mode="indeterminate"
        [style]="{'height': '3px','padding':'unset','width':'30%','position':'fixed'}"></p-progressBar>
    <div class="login-image">
        <img [src]="'assets/layout/images/pages/login-'+ (app.colorScheme === 'dark' ? 'ondark' : 'onlight') + '.png'"
            alt="atlantis">
    </div>
    <div class="login-panel p-fluid">
        <div class="p-d-flex p-flex-column">
            <div class="p-d-flex p-ai-center p-mb-6 logo-container">
                <img [src]="'assets/layout/images/logo-'+ (app.colorScheme === 'light' ? 'dark' : 'light') + '.png'"
                    class="login-logo" />
                <img [src]="'assets/layout/images/appname-'+ (app.colorScheme === 'light' ? 'dark' : 'light') + '.png'"
                    class="login-appname" />
            </div>
            <div class="form-container">
                <span class="p-input-icon-left">
                    <i class="pi pi-envelope"></i>
                    <input type="text" autocomplete="off" pInputText placeholder="Username" [(ngModel)]="item.namaUser">
                </span>
                <span class="p-input-icon-left">
                    <i class="pi pi-key"></i>
                    <input type="password" autocomplete="off" pInputText placeholder="Password"
                        [(ngModel)]="item.kataSandi" (keyup.enter)="loginKeun()">
                </span>
                <a href="#" class="p-d-flex">Forgot your password?</a>
            </div>
            <div class="button-container">
                <button type="button" pButton pRipple [disabled]="loading" label="Login" (click)="loginKeun()"><i
                        *ngIf="loading" class="pi pi-spin pi-spinner" style="margin-right: 0.5rem;"></i>
                    <i *ngIf="!loading" class="pi pi-arrow-right" style="margin-right: 0.5rem;"></i>
                </button>
                <!-- style="color: #ffffff;
                background: #2196F3;
                border: 1px solid #2196F3;" -->
                <!-- <button type="button" pButton label="Login" (click)="loginKeun()"></button>
                <span>Don’t have an account?<a>Sign-up here</a></span> -->
            </div>
        </div>

        <div class="login-footer p-d-flex p-ai-center">
            <div class="p-d-flex p-ai-center login-footer-logo-container">
                <img src="assets/layout/images/logo-gray.png" class="login-footer-logo" />
                <img src="assets/layout/images/appname-gray.png" class="login-footer-appname" />
            </div>
            <span>Copyright EREA 2021</span>
            <div class="p-d-flex p-ai-center login-footer-logo-dark">
                <a href="#"  *ngIf="!appMain.darkMode" (click)="appMain.onChangeTheme($event,'dark')" pTooltip="Mode Gelap" >
                    <i class="topbar-icon-login pi pi-fw pi-moon"></i>
                </a>
                <a href="#"  *ngIf="appMain.darkMode" (click)="appMain.onChangeTheme($event,'light')" pTooltip="Mode Terang" >
                    <i class="topbar-icon-login pi pi-fw pi-sun"></i>
                </a>
           </div>
        </div>
    </div>
</div>