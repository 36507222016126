import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;

    searchText = searchText.toLowerCase();
    return items.filter(it => {
      return (
        (it.name != undefined ? it.name.toLowerCase().includes(searchText) : '')
        ||
        (it.filter != undefined ? it.filter.toLowerCase().includes(searchText) : '')
        ||
        (it.filter2 != undefined ? it.filter2.toLowerCase().includes(searchText) : '')
      );
    });
  }
}