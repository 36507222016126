<div class="layout-menu-wrapper" [ngClass]="{'layout-sidebar-active': appMain.sidebarActive}"
    (click)="appMain.onSidebarClick($event)" (mouseover)="appMain.onSidebarMouseOver($event)"
    (mouseleave)="appMain.onSidebarMouseLeave($event)">
    <div class="menu-logo" *ngIf="!appMain.isHorizontal() || appMain.isMobile()">

        <a href="#" class="logo">
            <img [src]="'assets/layout/images/logo-'+ (app.colorScheme === 'light' ? 'dark' : 'light') + '.png'">
        </a>

        <a href="#" class="app-name" *ngIf="!appMain.isSlim() || appMain.isMobile()">
            <img [src]="'assets/layout/images/appname-'+ (app.colorScheme === 'light' ? 'dark' : 'light') + '.png'" />
        </a>
        <a href="#" class="menu-pin" (click)="appMain.onToggleMenu($event)">
            <span *ngIf="appMain.isOverlay()" class="pi pi-times"></span>
            <span *ngIf="appMain.isSidebar() && !appMain.sidebarStatic && appMain.pinActive"
                class="pi pi-unlock"></span>
            <span *ngIf="appMain.isSidebar() && appMain.sidebarStatic && appMain.pinActive" class="pi pi-lock"></span>
        </a>
    </div>

    <div class="layout-menu-container">
        <ul class="layout-menu">
            <div *ngIf="model == undefined" class="p-grid" style="padding: 0 3rem;margin-top:0">
                <div class="p-col-12 p-md-1" *ngFor="let itemzz of numberss">
                    <p-skeleton height="2rem"></p-skeleton>
                </div>
            </div>
          <ng-container *ngFor="let item of model; let i = index;">
                <li app-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
                <li *ngIf="item.separator" class="menu-separator"></li>
            </ng-container> 
        </ul>
    </div>

    <app-inlinemenu></app-inlinemenu>
</div>