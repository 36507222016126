import { Component, HostListener ,OnInit} from '@angular/core';
import { MenuService } from './app.menu.service';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { AppComponent } from './app.component';
import { Subscription } from 'rxjs';
import { ApiService, AuthService, LoaderService } from './service';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { NotificationService } from './service/notification.service';
import { SocketService } from './service/socket.service';

@Component({
    selector: 'app-main',
    templateUrl: './app.main.component.html'
})
export class AppMainComponent implements OnInit{
    overlayMenuActive: boolean;

    staticMenuDesktopInactive = false;

    staticMenuMobileActive: boolean;

    sidebarActive = false;

    sidebarStatic = false;

    menuClick: boolean;

    menuHoverActive = false;

    topbarMenuActive: boolean;

    topbarItemClick: boolean;

    activeTopbarItem: any;

    configActive: boolean;

    configClick: boolean;

    rightMenuActive: boolean;

    rightMenuClick: boolean;

    searchActive: boolean;

    searchClick: boolean;

    activeInlineProfile: boolean;

    pinActive: boolean;

    // add
    // add
    isLogin: boolean;
    subscription: Subscription;
    filteredMenu: any[]
    itemMenu: any;
    tanggalGlobal: any
    tanggalSelect: any
    formatCalendar: any
    msgs: any[]
    subscribDataFixed: Subscription
    isSettingDataFixed: boolean
    display: boolean = false
    skeleton: boolean
    namaProfile: string
    namaLogin: string
    jmlNotif: number = 0
    listNotif: any[] = []
    audio = new Audio();
    mapLogin: any[] = []
    darkMode:boolean = false
    constructor(private menuService: MenuService, private primengConfig: PrimeNGConfig, public app: AppComponent,
        // add
        public authService: AuthService,
        public router: Router,
        private messageService: MessageService,
        private loaderService: LoaderService,
        private apiService: ApiService,
        private socket: SocketService,

    ) {


        this.msgs = []

        if (localStorage.getItem('RUdJRVJBTURBTg==')) {
            this.isLogin = true

            let user = JSON.parse(localStorage.getItem('RUdJRVJBTURBTg=='))
            this.namaProfile = user.profile.namalengkap
            this.namaLogin = user.pegawai.namaLengkap
            this.authService.setDataLoginUser(JSON.parse(localStorage.getItem('RUdJRVJBTURBTg==')))
            this.mapLogin = this.authService.getMapLoginUserToRuangan()

        } else if (sessionStorage.getItem('RUdJRVJBTURBTg==')) {

            this.isLogin = true
            let user = JSON.parse(sessionStorage.getItem('RUdJRVJBTURBTg=='))
            this.namaProfile = user.profile.namalengkap
            this.namaLogin = user.pegawai.namaLengkap
            this.authService.setDataLoginUser(JSON.parse(sessionStorage.getItem('RUdJRVJBTURBTg==')))
            this.mapLogin = this.authService.getMapLoginUserToRuangan()
            this.loadNotif()
        } else {
            this.isLogin = false
        }
        // if(this.isLogin){

        this.socket.on('terima-notif', (data: any) => {
            var objectData = JSON.parse(data);
            if (this.mapLogin.length > 0) {
                for (let x = 0; x < this.mapLogin.length; x++) {
                    const element = this.mapLogin[x];
                    if (element.id == objectData.idRuanganTujuan) {
                        if (this.listNotif.length == 0) {
                            this.listNotif.push(objectData)
                        } else {
                            if (!this.listNotif.some(x => x.norec === objectData.norec)) {
                                this.listNotif.push(objectData);
                            }
                        }
                        this.messageService.add({ key: 'socket', severity: 'warn', summary: 'Notif ' + objectData.jenis, detail: JSON.stringify(objectData) ,life:5000});
                        // this.messageService.add({ key: 'br', severity: 'warn', summary: 'Notif ' + objectData.jenis, detail: objectData.judul });
                        this.listNotif.sort(this.compare);
                        this.jmlNotif = this.listNotif.length
                        this.audio.src = "assets/sound/toastToneSuccess.mp3";
                        this.audio.load();
                        this.audio.play();
                        this.savDB(objectData)
                    }
                }
            } else {

                // if (this.authService.getKelompokUser() == objectData.kelompokUser) {
                //     if (this.listNotif.length == 0) {
                //         this.listNotif.push(objectData)
                //     } else {
                //         if (!this.listNotif.some(x => x.norec === objectData.norec)) {
                //             this.listNotif.push(objectData);
                //         }
                //     }
                //     this.messageService.add({ key: 'br', severity: 'warn', summary: 'Notif ' + objectData.jenis, detail: objectData.judul });
                //     this.listNotif.sort(this.compare);
                //     this.jmlNotif = this.listNotif.length
                //     this.audio.src = "assets/sound/toastToneSuccess.mp3";
                //     this.audio.load();
                //     this.audio.play();
                //     this.savDB(objectData)
                // }
            }


        })
        // }
        router.events.subscribe((event: RouterEvent) => {
            this.navigationInterceptor(event)
        })

        this.subscription = this.authService.getLoginStatus().subscribe((login: any) => { this.isLogin = login })
        this.subscribDataFixed = this.loaderService.statusDataFixed.subscribe((state: boolean) => {
            this.isSettingDataFixed = state
        })
        // this.terjemah.generateTranslate()
        localStorage.removeItem('tanggalDefault')
        setInterval(() => {
            if (localStorage.getItem('tanggalDefault')) {
                let tgl = new Date(localStorage.getItem('tanggalDefault'))
                this.tanggalGlobal = new Date(tgl.getFullYear(), tgl.getMonth(), tgl.getDate(), tgl.getHours(), tgl.getMinutes(), tgl.getSeconds() + 1)
                localStorage.setItem('tanggalDefault', this.tanggalGlobal)
            } else {
                let tgl = new Date()
                this.tanggalGlobal = new Date(tgl.getFullYear(), tgl.getMonth(), tgl.getDate(), tgl.getHours(), tgl.getMinutes(), tgl.getSeconds() + 1)
                localStorage.setItem('tanggalDefault', this.tanggalGlobal)
            }
        }, 1000);
        this.formatCalendar = this.authService.getFormatTanggal()


    }
    ngOnInit(): void {

    }
    onSelectNotifToast(notif){
        notif = JSON.parse(notif)
        if (notif.urlForm != null && notif.urlForm != '') {
            let notrans = notif.judul.split('#')
            if (notrans.length > 0) {
                notrans = notrans[1]
            }
            this.router.navigate([notif.urlForm], { queryParams: { norec: notif.norec, notransaksi: notrans } })
        }
        this.apiService.postNonMessage('sysadmin/store-notif', { method: 'delete', norec: notif.norec }).subscribe(e => {
            for (var i = this.listNotif.length - 1; i >= 0; i--) {
                if (this.listNotif[i].norec === notif.norec) {
                    this.listNotif.splice(i, 1);
                }
            }
            this.jmlNotif = this.listNotif.length
        })
        this.messageService.clear('socket');
    }
    setJudulNotif(notif){
     return JSON.parse(notif).judul
    }
    sortBy(prop: string) {
        return this.listNotif.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
    }
    compare(a, b) {
        if (a.tgl > b.tgl) {
            return -1;
        }
        if (a.tgl < b.tgl) {
            return 1;
        }
        return 0;
    }
    loadNotif() {
        this.listNotif = []
        this.jmlNotif = 0
        this.apiService.postNonMessage('sysadmin/store-notif', { method: 'get' }).subscribe(e => {
            if (e.length > 0) {
                for (let x = 0; x < e.length; x++) {
                    const element = e[x];
                    if (this.mapLogin.length > 0) {
                        for (let z = 0; z < this.mapLogin.length; z++) {
                            const element2 = this.mapLogin[z];
                            if (element2.id == element.ruangantujuanidfk) {
                                this.listNotif.push({
                                    norec: element.norec,
                                    judul: element.judul,
                                    jenis: element.jenis,
                                    pesanNotifikasi: element.keterangan,
                                    idRuanganAsal: element.ruanganasalidfk,
                                    idRuanganTujuan: element.ruangantujuanidfk,
                                    ruanganAsal: element.ruanganasal,
                                    ruanganTujuan: element.ruangantujuan,
                                    kelompokUser: element.kelompokuser,
                                    idKelompokUser: element.kelompokuseridfk,
                                    dataArray: null,
                                    urlForm: null,
                                    idPegawai: element.pegawaiidfk,
                                    namaFungsiFrontEnd: null,
                                    tgl: element.tgl,
                                    tgl_string: element.tgl_string,
                                })
                            }
                        }
                    }
                    // else{
                    //     if (this.authService.getKelompokUser() == element.kelompokuser) {
                    //         this.listNotif.push({
                    //             norec: element.norec,
                    //             judul: element.judul,
                    //             jenis: element.jenis,
                    //             pesanNotifikasi: element.keterangan,
                    //             idRuanganAsal: element.ruanganasalidfk,
                    //             idRuanganTujuan: element.ruangantujuanidfk,
                    //             ruanganAsal: element.ruanganasal,
                    //             ruanganTujuan: element.ruangantujuan,
                    //             kelompokUser: element.kelompokuser,
                    //             idKelompokUser: element.kelompokuseridfk,
                    //             dataArray: null,
                    //             urlForm: null,
                    //             idPegawai: element.pegawaiidfk,
                    //             namaFungsiFrontEnd: null,
                    //             tgl: element.tgl,
                    //             tgl_string: element.tgl_string,
                    //         })
                    //     }
                    // }

                }
                this.jmlNotif = this.listNotif.length
            }
        })
    }
    savDB(data) {
        data.method = 'save'
        // this.apiService.postNonMessage('sysadmin/store-notif', data).subscribe(e => { })
    }
    onLayoutClick() {
        if (!this.topbarItemClick) {
            this.activeTopbarItem = null;
            this.topbarMenuActive = false;
        }

        if (this.configActive && !this.configClick) {
            this.configActive = false;
        }

        if (this.rightMenuActive && !this.rightMenuClick) {
            this.rightMenuActive = false;
        }

        if (this.searchActive && !this.searchClick) {
            this.searchActive = false;
        }

        if (!this.menuClick) {
            if ((this.isSlim() || this.isHorizontal()) && !this.isMobile()) {
                this.menuService.reset();
                this.menuHoverActive = false;
            }

            if (this.overlayMenuActive || this.staticMenuMobileActive) {
                this.overlayMenuActive = false;
                this.staticMenuMobileActive = false;
            }
        }

        this.configClick = false;
        this.rightMenuClick = false;
        this.searchClick = false;
        this.menuClick = false;
        this.topbarItemClick = false;
    }

    onSidebarClick($event) {
        this.menuClick = true;
    }

    onToggleMenu(event) {
        this.menuClick = true;

        if (this.overlayMenuActive) {
            this.overlayMenuActive = false;
        }

        if (this.sidebarActive) {
            this.sidebarStatic = !this.sidebarStatic;
        }

        event.preventDefault();
    }

    onSidebarMouseOver(event) {
        if (this.app.menuMode === 'sidebar' && !this.sidebarStatic) {
            this.sidebarActive = this.isDesktop();
            setTimeout(() => {
                this.pinActive = this.isDesktop();
            }, 200);
        }
    }

    onSidebarMouseLeave($event) {
        if (this.app.menuMode === 'sidebar' && !this.sidebarStatic) {
            setTimeout(() => {
                this.sidebarActive = false;
                this.pinActive = false;
            }, 250);
        }
    }

    onMenuButtonClick(event) {
        this.menuClick = true;

        if (this.isOverlay()) {
            this.overlayMenuActive = !this.overlayMenuActive;
        }

        if (this.isDesktop()) {
            this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
        } else {
            this.staticMenuMobileActive = !this.staticMenuMobileActive;
        }

        event.preventDefault();
    }

    onTopbarItemClick(event, item) {
        this.topbarItemClick = true;

        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null;
        } else {
            this.activeTopbarItem = item;
        }

        event.preventDefault();
    }

    onTopbarSubItemClick(event) {
        event.preventDefault();
    }
    onSelectNotif(event, daya) {
        if (daya.urlForm != null && daya.urlForm != '') {
            this.router.navigate([daya.urlForm])
        }
        this.apiService.postNonMessage('sysadmin/store-notif', { method: 'delete', norec: daya.norec }).subscribe(e => {
            for (var i = this.listNotif.length - 1; i >= 0; i--) {
                if (this.listNotif[i].norec === daya.norec) {
                    this.listNotif.splice(i, 1);
                }
            }
            this.jmlNotif = this.listNotif.length
        })

        event.preventDefault();
    }

    onRippleChange(event) {
        this.app.ripple = event.checked;
        this.primengConfig.ripple = event.checked;
    }

    onConfigClick(event) {
        this.configClick = true;
    }

    onRightMenuButtonClick() {
        this.rightMenuClick = true;
        this.rightMenuActive = true;
    }

    onRightMenuClick($event) {
        this.rightMenuClick = true;
    }

    isStatic() {
        return this.app.menuMode === 'static';
    }

    isOverlay() {
        return this.app.menuMode === 'overlay';
    }

    isSlim() {
        return this.app.menuMode === 'slim';
    }

    isHorizontal() {
        return this.app.menuMode === 'horizontal';
    }

    isSidebar() {
        return this.app.menuMode === 'sidebar';
    }

    isDesktop() {
        return window.innerWidth > 991;
    }

    isMobile() {
        return window.innerWidth <= 991;
    }

    // add

    onConfirm() {
        this.messageService.clear('t-logout');
        // this.notifications.logout()
        this.authService.logout()
    }
    onReject() {
        this.messageService.clear('t-logout');
    }
    navigationInterceptor(event: RouterEvent): void {
        if (event instanceof NavigationStart) {
            this.loaderService.show()
        }
        if (event instanceof NavigationEnd) {
            this.loaderService.hide()
        }
        if (event instanceof NavigationCancel) {
            this.loaderService.hide()
        }
        if (event instanceof NavigationError) {
            this.loaderService.hide()
        }
    }

    @HostListener('document:mouseenter', ['$event'])
    onMouseEnter(e) {
        let local = JSON.parse(localStorage.getItem('RUdJRVJBTURBTg=='))
        let session = JSON.parse(sessionStorage.getItem('RUdJRVJBTURBTg=='))
        if (!local && !session && this.router.url != '/login') {
            this.display = true
        } else {
            // console.log('Sudah Logout')
        }
        if (local != null && session != null) {
            if (new Date() > new Date(local.expired)) {
                this.display = true
            }
        }


    }
    keluar() {
        this.display = false
        this.authService.logout()
    }
    logout() {
        // this.authService.logout()
        this.messageService.clear();
        this.messageService.add({ key: 't-logout', sticky: true, severity: 'warn', summary: 'Yakin Mau Logout', detail: '' });
    }

    getTanggalGlobal() {
        let tgl = new Date(localStorage.getItem('tanggalDefault'))
        this.tanggalSelect = tgl
    }
    setTanggalGlobal() {
        localStorage.setItem('tanggalDefault', this.tanggalSelect)
    }
    resetTanggal() {
        this.tanggalSelect = new Date()
    }
    filterMenu(event: any) {
        let query = event.query;
        let data = this.authService.getListRoute()

        // this.filteredMenu = this.filterMenuItem(query, data);
        this.filteredMenu = data.filter(route => {
            return route.label.toLowerCase().includes(query.toLowerCase())
        })
    }
    onSelectSearchMenu(event: any) {
        this.router.navigate(event.routerLink);
    }
    onChangeTheme(event,scheme) {
        event.preventDefault();
        if(scheme == 'dark'){
            this.darkMode = true
        }else{
            this.darkMode = false
        }
        this.changeStyleSheetsColor('layout-css', 'layout-' + scheme + '.css', 1);
        this.changeStyleSheetsColor('theme-css', 'theme-' + scheme + '.css', 1);

        this.app.colorScheme = scheme;
    }

    changeStyleSheetsColor(id, value, from) {
        const element = document.getElementById(id);
        const urlTokens = element.getAttribute('href').split('/');

        if (from === 1) {           // which function invoked this function - change scheme
            urlTokens[urlTokens.length - 1] = value;
        } else if (from === 2) {       // which function invoked this function - change color
            urlTokens[urlTokens.length - 2] = value;
        }

        const newURL = urlTokens.join('/');

        this.replaceLink(element, newURL);
    }
    replaceLink(linkElement, href) {
        if (this.isIE()) {
            linkElement.setAttribute('href', href);
        } else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);
            });
        }
    }
    isIE() {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    }

    profile(event) {
        this.router.navigate(['detail-pegawai', this.authService.getPegawaiId()])
        event.preventDefault();
    }
}
