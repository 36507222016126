import { Injectable } from '@angular/core';
import { Config } from '../guard';
import * as io from 'socket.io-client';
import { NotifInfo } from './notification.interface';
import { ApiService } from './api.service';
// class CallBackWrapper {

// 	private iCallBack : (ob : any, res : string) => any = null;

// 	constructor(callBack : (ob : any, res : string) => any = null, private ob : any){
// 		this.iCallBack = callBack;
// 	}	

// 	callBackOn(data){
// 		console.log('data  asli ' + data);
// 		this.iCallBack(this.ob, data);
// 	}
// }

@Injectable()
export class SocketService {

	private socket: any;
	//wrapper : CallBackWrapper[]; 

	private callBack: (data: string) => any = null

	constructor(private apiService: ApiService,) {
		this.socket = io(Config.get().socketIO,
			{
				transports: ['websocket', 'polling'],
			});
		console.log('socket connect');
	}



	on(stat, callBack: (data: string) => any = null) {
		//this.wrapper[ob] = new CallBackWrapper(callBack, ob);
		this.callBack = callBack;
		this.socket.on(stat, function (data) {
			//console.log('data  asli ' + data);
			callBack(data);
		});
	}

	emit(info, message) {
		this.socket.emit(info, message);
	}

	sendNotif(message:NotifInfo) {
		this.socket.emit('send-notif', message);
		let json:any = message
		json.method = 'save'
        this.apiService.postNonMessage('sysadmin/store-notif', json).subscribe(e => { })
	}


}

