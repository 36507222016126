<div [ngClass]="{'layout-inline-menu': true, 'layout-inline-menu-active':appMain.activeInlineProfile}" *ngIf="!appMain.isMobile() && (appMain.isStatic() || appMain.isSlim() || appMain.isSidebar())">
    <a class="layout-inline-menu-action p-d-flex p-dir-row p-ai-center" (click)="appMain.activeInlineProfile = !appMain.activeInlineProfile">
        <img src="assets/layout/images/profile-image.png" alt="avatar" style="width: 44px; height: 44px;">
        <span class="layout-inline-menu-text p-d-flex p-flex-column p-ml-2">  {{appMain.namaLogin}}</span>
        <i class="layout-inline-menu-icon pi pi-angle-down p-ml-auto"></i>
    </a>

    <ul class="layout-inline-menu-action-panel" [@inline]="appMain.activeInlineProfile ? 'visibleAnimated' : 'hiddenAnimated'">
        <li class="layout-inline-menu-action-item">
            <a class="p-d-flex p-flex-row p-ai-center"  (click)="appMain.logout()">
                <i class="pi pi-power-off pi-fw"></i>
                <span>Logout</span>
            </a>
        </li>
        <li class="layout-inline-menu-action-item">
            <a class="p-d-flex p-flex-row p-ai-center">
                <i class="pi pi-cog pi-fw"></i>
                <span>Settings</span>
            </a>
        </li>
        <li class="layout-inline-menu-action-item">
            <a class="p-d-flex p-flex-row p-ai-center">
                <i class="pi pi-user pi-fw"></i>
                <span>Profile</span>
            </a>
        </li>
    </ul>
</div>
