import { Component, OnInit } from '@angular/core';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';

@Component({
    selector: 'app-footer',
    template: `
        <div class="layout-footer">
            <div class="footer-logo-container">
                <img id="footer-logo"   [src]="'assets/layout/images/logo-'+ (app.colorScheme === 'light' ? 'dark' : 'light') + '.png'" alt="atlantis-layout"/>
                <span class="app-name">Netmedic</span>
                <img style="margin-left:20px;width:auto;" [src]="'assets/layout/images/logo_rs.png'"/>
                <span class="app-name">{{namaProfile}}</span>
     

            </div>
            <span class="copyright">&#169; EREA - 2021</span>
        </div>
    `
})
export class AppFooterComponent implements OnInit {
    namaProfile = ''
    constructor(public app: AppComponent) { }
    ngOnInit() {
        if (localStorage.getItem('RUdJRVJBTURBTg==')) {
            let user = JSON.parse(localStorage.getItem('RUdJRVJBTURBTg=='))
            this.namaProfile = user.profile.namalengkap

        } else if (sessionStorage.getItem('RUdJRVJBTURBTg==')) {
            let user = JSON.parse(sessionStorage.getItem('RUdJRVJBTURBTg=='))
            this.namaProfile = user.profile.namalengkap

        }

    }
}
