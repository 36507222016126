import {Component, OnInit} from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
    listMenu = ['sidebar','horizontal','static','slim','overlay'];

    menuMode = this.listMenu[1];     

    layout = 'yellow';

    theme = 'yellow';

    ripple: boolean;

    colorScheme = 'light';

    constructor(private primengConfig: PrimeNGConfig) {
    }

    ngOnInit() {
        this.primengConfig.ripple = true;
        this.ripple = true;
    }
}
